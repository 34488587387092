/*
external-api-service.js
Adam Berger
July 2023
*/

import axios from "axios";

const MAX_RETRIES = 3
const WAIT_BETWEEN_RETRIES = 1500 // ms

export const callExternalApi = async (url, accessToken) => {
  const config = {
    method: 'GET',
    url: url,
    headers: { 
      'Authorization': `Bearer ${accessToken}`
    }
  };

  return _callExternalApiWithRetries(config, MAX_RETRIES)
}

// ====== internal functions ======

function _delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const _callExternalApiWithRetries = async (config, numRetriesRemaining) => {
  try {
    const response = await axios(config);
    const { data } = response;
    return {
      data,
      error: null,
    };
  } catch (error) {
    
    // set up object to return 
    const r = {
      data: null,
      error: { message: error.message, },
    };

    if (error.response) {
      // Server responded with a non-2xx status - return immediately 
      console.error("HTTP Error: %s", error.response.status)
      return r 
    } else if (error.request || error.code === 'ECONNABORTED') {
      // Request was sent but no response was received, or connection aborted. Try again.  
      if (numRetriesRemaining > 0) {
        console.log(`"Network Error or No Response. Retry attempt remaining: ${numRetriesRemaining}`);
        console.log("URL: %s; error=%s", config.url, error)
        await _delay(WAIT_BETWEEN_RETRIES)
        return _callExternalApiWithRetries(config, numRetriesRemaining - 1);
      }
      console.log(`Network call failed after ${MAX_RETRIES} attempts.`)
      return r       
    } else {
      // Something else happened while setting up the request - return immediately 
      console.error("Request Setup Error:", error.message);
      return r 
    } 
  }
}