import React, {useEffect, useState} from "react";
import * as Constants from "../Constants";
import PropTypes from "prop-types";

export const CategorySelector = ({recipe, setUserHasMadeEdits, setRecipe}) => {
    const [category, setCategory] = useState(Constants.DEFAULT_CATEGORY);

    useEffect(() => {
        if (recipe && recipe.category) {
            setCategory(recipe.category);
        }
    }, [recipe]);

    const handleChange = (event) => {
        setUserHasMadeEdits(true);
        const newCategory = event.target.value;
        setCategory(newCategory);
        const updatedRecipe = {...recipe, category: newCategory};
        setRecipe(updatedRecipe);
    };

    return (
        <div className="input-group">
            <label className="input-label">
                Category: &nbsp;
                <select
                    className="category-select-input"
                    value={category}
                    onChange={handleChange}
                >
                    {Constants.CATEGORIES.map((cat) => (
                        <option className="category-option-input" key={cat} value={cat}>
                            {cat.charAt(0).toUpperCase() + cat.slice(1)}
                        </option>
                    ))}
                </select>
            </label>
        </div>
    );
}

CategorySelector.propTypes = {
    recipe: PropTypes.object.isRequired,
    setRecipe: PropTypes.func.isRequired,
    setUserHasMadeEdits: PropTypes.func.isRequired,
    // Either remove this line, or make it optional:
    // handleChange: PropTypes.func.isRequired,
    handleChange: PropTypes.func,
  };
  
