import React, { useState, useMemo } from 'react';
import { useSearchBox } from 'react-instantsearch-hooks-web';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

export function DebouncedSearchBox({ placeholder }) {
 
  const { query, refine } = useSearchBox();
 
  const [inputValue, setInputValue] = useState(query);

  // Memoized debounced refine function
  const debouncedRefine = useMemo(
    () =>
      debounce((value) => {
        refine(value);
      }, 400), 
    [refine]
  );

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    debouncedRefine(newValue);
  };

  return (
    <div className="browse-search-container">
      <input
        type="search"
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
      />
      
    </div>
  );
}

DebouncedSearchBox.propTypes = {
    placeholder: PropTypes.string,
  };
  