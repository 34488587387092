/*
ShareModal.js
Adam Berger
July 2023
*/

import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import * as Constants from "components/Constants";
import { callExternalApi } from "components/external-api.service";
import ModalWithAck from "components/ModalWithAck";
import ModalWithoutAck from "components/ModalWithoutAck";
import LoadingSpinner from "./LoadingSpinner";
import CopyToClipboard from "./CopyToClipboard";

//import assets
import modalX from "assets/icons/modal-x.svg";
import share from "assets/icons/share.svg";
import linkIcon from "assets/icons/LinkSimpleHorizontal.png";

export default function ShareModal({ backdrop, toggle, guid, onClick }) {
  const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [recipientEmail, setRecipientEmail] = useState("");
  const [ackMessage, setAckMessage] = useState("");
  const [invalidInputMessage, setInvalidInputMessage] = useState("");
  const [shareableLink, setShareableLink] = useState("");
  const navigate = useNavigate();

  // valid UI states: init, show_shareable_link, select_recipient, invalid_input, processing, ack, done
  const [viewState, setViewState] = useState("init");

  const CACHE_SIZE = 5; // how many recent recipients to remember
  const MESSAGE_WHEN_RECIPIENT_HAS_ACCT =
    "I've shared your recipe with ##RECIPIENT##. They'll receive an email with the recipe. Plus, a copy of recipe will appear in their cookbook the next time they use Kasserole.";
  const MESSAGE_WHEN_RECIPIENT_DOESNT_HAVE_ACCT =
    "I've shared your recipe with ##RECIPIENT##. They'll receive an email with the recipe.";

  function getCacheKey() {
    return "kasserole_recipients_" + user.name;
  }

  const storeRecipientInLocalCache = (recipient) => {
    // it's an LRU cache, organized as a single long string with spaces between each email address.
    // Move 'recipient' to the front. Truncate at CACHE_SIZE entries.
    var recipients = localStorage.getItem(getCacheKey());
    if (recipients == null) {
      recipients = "";
    }

    // put 'recipient' at the front of the list
    recipients = recipients.replace(recipient, "");
    recipients = recipient + " " + recipients;

    // truncate the list to CACHE_SIZE entries
    const parts = recipients.trim().split(/\s+/);
    if (parts.length > CACHE_SIZE) {
      recipients = parts.slice(0, CACHE_SIZE).join(" ");
    }
    localStorage.setItem(getCacheKey(), recipients);
  };

  const getRecipientsFromLocalCache = () => {
    // stored in localStorage as a space-delimited string of emails. Return as an array of emails.
    const s = localStorage.getItem(getCacheKey());
    if (s == null || s.trim() === "") {
      return [];
    }
    return s.trim().split(/\s+/);
  };

  const neverMind = () => {
    onClick();
  };

  function generateShareableLink() {
    setShareableLink(window.location.origin + "/el/" + guid);
    _setViewState("show_shareable_link");
  }

  function pickFriend() {
    _setViewState("select_recipient");
  }

  // convenience function for debugging
  function _setViewState(newState) {
    // console.log("Setting view state to " + newState)
    setViewState(newState);
  }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const sendEmail = async () => {
    const valid = isValidEmail(recipientEmail);
    if (!valid) {
      _setViewState("invalid_input");
      setInvalidInputMessage(
        "That email address (" +
          recipientEmail +
          ") does not appear to be valid."
      );
      return;
    }

    const accessToken = await Constants.getAccessTokenSilentlyWithTimeout(
      getAccessTokenSilently,
      loginWithRedirect,
      navigate
    );
    const encodedUser = Constants.encodeUser(user);
    const encodedRecipientEmail = Constants.encodeEmail(recipientEmail);
    const url = Constants.generateSecureApiUrl(
      Constants.APIG_SHARE_RESOURCE,
      "user=" +
        encodedUser + // encoded sharer
        "&uuser=" +
        user.name + // unencoded sharer
        "&guid=" +
        guid +
        "&recipient=" +
        recipientEmail + // unencoded recipient
        "&erecipient=" +
        encodedRecipientEmail
    ); // encoded recipient
    callExternalApi(url, accessToken).then((result) => {
      // the lambda tells us, in the response, whether the recipient has an account or not
      const { data } = result;
      const recipientHasAccount = data != null && data.includes("has_account");
      var msg = recipientHasAccount
        ? MESSAGE_WHEN_RECIPIENT_HAS_ACCT
        : MESSAGE_WHEN_RECIPIENT_DOESNT_HAVE_ACCT;
      msg = msg.replace("##RECIPIENT##", recipientEmail);
      setAckMessage(msg);
      storeRecipientInLocalCache(recipientEmail);
      _setViewState("ack");
    });
  };

  const recentRecipients = getRecipientsFromLocalCache(); // an array of strings (email addresses)

  if (viewState == "init") {
    return (
      <div>
        <Modal
          backdrop={backdrop}
          toggle={toggle}
          isOpen={true}
          className="modal-dialog"
        >
          <div className="modal-header">
            <div className="modal-header-flex">
              <h5 className="modal-header-text">Share this recipe</h5>
              <img
                className="modal-header-x"
                src={modalX}
                onClick={neverMind}
              />
            </div>
          </div>
          <ModalBody>
            <div className="share-modal-row">
              <button
                className="share-modal-btn share-modal-btn-dark"
                onClick={() => generateShareableLink()}
              >
                <img src={linkIcon} />
               Link
              </button>
              <div>
                <div className="modal-body-text">
                    Get a link to this recipe, which you can post to your favorite social media site or share via text or email.
                </div>
              </div>
            </div>
            <div className="share-modal-row">
              <button
                className="share-modal-btn share-modal-btn-dark"
                onClick={() => pickFriend()}
              >
                <img src={share} />
                Email 
              </button>
              <div>
                <div className="modal-body-text">
                  Send this recipe to a friend by entering their email.
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  } else if (viewState == "show_shareable_link") {
    return (
      <div>
        <Modal
          backdrop={backdrop}
          toggle={toggle}
          isOpen={true}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <div className="modal-header-flex">
              <h5 className="modal-header-text">Shareable Link</h5>
              <img
                className="modal-header-x"
                src={modalX}
                onClick={neverMind}
              />
            </div>
          </div>
          <ModalBody>
            <div className="share-modal-body-header">
              Here&apos;s your shareable link:
            </div>

            <CopyToClipboard textToCopy={shareableLink} />

            <div className="modal-body-text">
              You can put this in Facebook, Instagram, X, or any web page.
              Anyone who clicks on this will add this recipe to their Kasserole
              cookbook.
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  } else if (viewState == "select_recipient") {
    return (
      <div>
        <Modal
          backdrop={backdrop}
          toggle={toggle}
          isOpen={true}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <div className="modal-header-flex">
              <h5 className="modal-header-text">Select Recipient</h5>
              <img
                className="modal-header-x"
                src={modalX}
                onClick={neverMind}
              />
            </div>
          </div>{" "}
          <ModalBody>
            <div className="share-modal-body-header">
              Enter the email you would like to share this recipe with:
            </div>
            <div className="share-modal-input-container">
              <input
                className="share-modal-text-input"
                placeholder="friend@email.com"
                value={recipientEmail}
                onChange={(e) => setRecipientEmail(e.target.value)}
              />
              <button
                className="share-modal-btn share-modal-btn-dark share-modal-send-btn"
                onClick={() => {
                  _setViewState("processing");
                  sendEmail();
                }}
              >
                <img src={share} />
                Send
              </button>
            </div>

            {recentRecipients.length > 0 && (
              <>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "4px" }}
                >
                  <div className="share-modal-body-header">
                    Recent recipients
                  </div>
                  <div className="share-modal-body-nonbold">
                    (click to autofill):
                  </div>
                </div>

                <div className="share-modal-recipients-container">
                  {recentRecipients.map((value, index) => (
                    <button
                      className="share-modal-recipient"
                      key={index}
                      onClick={() => setRecipientEmail(value)}
                      style={{ paddingLeft: "10px" }}
                    >
                      {value}
                    </button>
                  ))}
                </div>
              </>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  } else if (viewState == "invalid_input") {
    return (
      <ModalWithAck
        backdrop={backdrop}
        toggle={toggle}
        title="Invalid Input"
        message={invalidInputMessage}
        onClick={() => _setViewState("init")}
      />
    );
  } else if (viewState == "processing") {
    return (
      <Modal
        backdrop={backdrop}
        toggle={toggle}
        isOpen={true}
        className="modal-dialog-centered share-modal-loading"
      >
        <div className="modal-header">
          <div className="modal-header-flex">
            <h5 className="modal-header-text">Select Recipient</h5>
            <img className="modal-header-x" src={modalX} onClick={neverMind} />
          </div>
        </div>{" "}
        <ModalBody>
          <div className="share-modal-loading-container">
            <LoadingSpinner />
          </div>
        </ModalBody>
      </Modal>
    );
  } else if (viewState == "ack") {
    return (
      <ModalWithoutAck
        backdrop={backdrop}
        toggle={toggle}
        success={true}
        title="Success"
        message={ackMessage}
        delay={5000}
        onClose={() => {
          onClick();
        }}
      />
    );
  } else if (viewState == "done") {
    onClick();
  } else throw new Error("invalid viewState: " + viewState);
}

ShareModal.propTypes = {
  guid: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  backdrop: PropTypes.bool,
  toggle: PropTypes.func,
};
