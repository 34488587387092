/*
RecipeFooter.js
Adam Berger
July 2023
*/

import React from "react";
import PropTypes from "prop-types";
import * as Constants from "components/Constants";
import eye from "assets/icons/Eye.svg";
import calendar from "assets/icons/CalendarBlank.svg";
import link from "assets/icons/hyperlink.svg";

export default function RecipeFooter({
  sourceUrl,
  tse,
  vc,
  sharer,
  userOwnsThisRecipe,
  visibility,
}) {
  function domainFromUrl(url) {
    try {
      let domain = new URL(url).origin;
      return domain;
    } catch (error) {
      return "";
    }
  }

  function tseToDate(timestamp) {
    const timestampMs = Math.round(parseFloat(timestamp));
    if (isNaN(timestampMs)) return ""; // handle invalid timestamp
    const dateObj = new Date(timestampMs);
    const lastModified = dateObj.toDateString() + " at " + dateObj.toLocaleTimeString();
    return "Last modified "+ lastModified
  }

  const lastModifiedDate = tse ? tseToDate(tse) : "";

  return (
    <div className="detail-footer">
      <div className="detail-footer-recipe-info">
        {sourceUrl && (
          <div className="detail-recipe-source">
            Recipe source:{" "}
            <span className="detail-recipe-source-link">
              <a target="_blank" rel="noreferrer" href={sourceUrl}>
                <img src={link} />
                {domainFromUrl(sourceUrl)}
              </a>
            </span>
          </div>
        )}
        <div className="detail-footer-container">
          {vc && (
            <div className="detail-footer-group">
              <img className="detail-footer-calendar" src={eye} />
              {vc}
            </div>
          )}
          {lastModifiedDate && (
            <div className="detail-footer-group">
              {lastModifiedDate}
            </div>
          )}
        </div>
      </div>

      {userOwnsThisRecipe && visibility == Constants.VISIBILITY_PRIVATE && (
        <>
          Recipe is private. The only people who can see it are you and those people
          you share it with in Kasserole.
        </>
      )}
      {userOwnsThisRecipe && visibility == Constants.VISIBILITY_PUBLIC && (
        <>Recipe is public. Anyone using Kasserole can see it.</>
      )}
    </div>
  );
}

RecipeFooter.propTypes = {
  sharer: PropTypes.string,
  sourceUrl: PropTypes.string,
  vc: PropTypes.string,
  tse: PropTypes.string,
  userOwnsThisRecipe: PropTypes.bool,
  visibility: PropTypes.string,
};

RecipeFooter.defaultProps = {
  sharer: "",
  sourceUrl: "",
  vc: "",
  tse: "",
  userOwnsThisRecipe: false,
  visibility: Constants.VISIBILITY_PRIVATE,
};
