/*
Edit.js
Adam Berger
July 2023

pass in guid=<guid> to edit an existing recipe, and guid='new' to create a new one from scratch.
either way, this component presents a form (prepopulated or empty) for the user to fill out.
*/

import React from "react";
import Button from "components/Button";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Navbar from "components/Navbar";
import bgImage from "assets/images/2.png";
import * as Constants from "components/Constants";
import RecipeFooter from "components/RecipeFooter";
import RecipePageBackground from "components/RecipePageBackground";
import ModalWithAck from "components/ModalWithAck";
import ModalWithoutAck from "components/ModalWithoutAck";
import LoadingSpinner from "components/LoadingSpinner";

//import assets (April 2024)
import homeImg from "assets/images/home_img.jpg";
import wand from "assets/icons/wand-white.svg";
import wandGrey from "assets/icons/wand.svg";
import camera from "assets/icons/camera.svg";
import checkmark from "assets/icons/check-mark-white.svg";
import cancel from "assets/icons/cancel-x.svg";
import toggleOn from "assets/icons/TogglerOn.svg";
import toggleOff from "assets/icons/TogglerOff.svg";
import { CategorySelector } from "../../components/EditPageComponents";
import { useEdit } from "./useEdit";
import "../../components/newRecipe.css";


const RECIPE_PLACEHOLDER = `Ingredients:
2 sticks of butter
2 eggs, beaten
1 cup of sugar
1.5 cups of flour.

Directions:
Preheat oven to 350 degrees.
Mix ingredients together.
Bake for 30 minutes.
Enjoy! `;

export const Edit = () => {
  const {
    modalErrorMessage,
    setModalErrorMessage,
    recipe,
    recipe_is_new,
    handleChangeInName,
    setRecipe,
    setUserHasMadeEdits,
    handleChangeInBody,
    imageFileToUploadAsB64,
    handleImageFileChange,
    submit,
    toggleVisibility,
    userOwnsThisRecipe,
    userHasMadeEdits,
    navigate,
    saveModalIsActive,
    getUpdateOrNewMessage,
    setSaveModalIsActive,
    invalidInputModalIsActive,
    whyRecipeIsInvalid,
    setInvalidInputModalIsActive,
    imageSelectorModalIsActive,
    imageFileToUpload,
    useSelectedImage,
    cancelSelectedImage,
  } = useEdit();

  /* if there's an error, show error message */
  if (modalErrorMessage) {
    return (
      <RecipePageBackground bgImage={bgImage}>
        <ModalWithAck
          title="Error"
          message={modalErrorMessage}
          onClick={() => setModalErrorMessage("")}
        />
      </RecipePageBackground>
    );
  }

  /* if we're still loading the recipe, show a spinner */
  if (recipe == null || recipe.recipe_name == null) {
    return <LoadingSpinner />;
  }

  /* Good to go. Show recipe deets...and possibly a modal above it */
  return (
    <>
      <div className="app-container">
        <Navbar />
        <div className="main-container">
          <div className="recipe-hero-img-container">
            <img className="recipe-hero-img" src={homeImg} />
            <div className="recipe-hero-img-overlay"></div>
            <div className="recipe-hero-content">
              <h5 className="recipe-hero-main-text">
                {recipe_is_new ? <>New Recipe</> : <>Edit Recipe</>}
              </h5>
            </div>
          </div>
          <div className="form-container">
            <div className="input-line-one">
              <div className="input-group">
                <label className="input-label">Name:</label>
                <input
                  value={recipe.recipe_name}
                  className="recipe-text-input"
                  placeholder="Aunt Betty's Famous Crepes"
                  onChange={handleChangeInName}
                />
              </div>
              <div>
                <CategorySelector
                  setRecipe={setRecipe}
                  setUserHasMadeEdits={setUserHasMadeEdits}
                  recipe={recipe}
                />
              </div>
            </div>
            <div className="input-group">
              <label className="input-label">Recipe:</label>
              <textarea
                className="edit-textarea"
                placeholder={RECIPE_PLACEHOLDER}
                onChange={handleChangeInBody}
                value={recipe.recipe_body}
              />
            </div>

            {recipe_is_new ? (
              <>
                <label htmlFor="file-upload" className="custom-file-upload">
                  <img
                    src={camera}
                    style={{ width: "16.25px", height: "14.38px" }}
                  />
                  {imageFileToUploadAsB64 ? "Change Picture" : "Upload Picture"}
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleImageFileChange}
                />

                {imageFileToUploadAsB64 && (
                  <img
                    src={imageFileToUploadAsB64}
                    alt="image"
                    className="create-from-image-preview"
                  />
                )}

                <div className="new-recipe-disclaimer">
                  By default, all new recipes are public, meaning that all
                  Kasserole users can see them. If you want to make a recipe
                  private, you can do so after it&apos;s created by editing that
                  recipe.
                </div>

                <div>
                  <div
                    style={{
                      borderTop: "1px solid #E1E7ED",
                      width: "100%",
                      marginBottom: "24px",
                    }}
                  ></div>
                  <div
                    className={`create-recipe-btn${
                      !imageFileToUploadAsB64 ? "-disabled" : ""
                    }`}
                    onClick={() => submit()}
                  >
                    <img src={imageFileToUploadAsB64 ? wand : wandGrey} />
                    <div className="create-recipe-btn-text">Craft Recipe</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <label
                    htmlFor="file-upload"
                    className="custom-file-upload no-border"
                  >
                    <img
                      src={camera}
                      style={{ width: "16.25px", height: "14.38px" }}
                    />
                    Change Picture
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleImageFileChange}
                  />
                  <img
                    src={
                      !imageFileToUploadAsB64
                        ? recipe.full_image_url
                        : imageFileToUploadAsB64
                    }
                    alt="image"
                    className="new-image"
                  />
                </div>

                {recipe.visibility === Constants.VISIBILITY_PRIVATE && (
                  <div className="edit-visibility-container">
                    <div className="edit-visibility-disclaimer">
                      This recipe is <b>private</b>. The only people who can see
                      it are you and anyone you share it with in Kasserole.
                    </div>
                    <div
                      className="visibility-toggle-container"
                      onClick={() => toggleVisibility()}
                    >
                      <img className="toggler-icon" src={toggleOff} />
                      <div>Share Recipe Publicly</div>
                    </div>
                  </div>
                )}

                {recipe.visibility === Constants.VISIBILITY_PUBLIC && (
                  <div className="edit-visibility-container">
                    <div className="edit-visibility-disclaimer">
                      This recipe is <b>public</b>. Anyone using Kasserole can
                      see it.<br></br>If you make it private, no one will see it
                      except those people you share it with in Kasserole.
                    </div>
                    <div
                      className="visibility-toggle-container"
                      onClick={() => toggleVisibility()}
                    >
                      <img className="toggler-icon" src={toggleOn} />
                      <div>Share Recipe Publicly</div>
                    </div>
                  </div>
                )}
  
                <RecipeFooter
                  sourceUrl={recipe.source_url}
                  tse={recipe.tse}
                  sharer={recipe.sf}
                  userOwnsRecipe={userOwnsThisRecipe}
                  visibility={recipe.visibility}
                />

                <div className="btns-container">
                  <div className="create-recipe-btn" onClick={() => submit()}>
                    <img className="save-recipe-checkmark" src={checkmark} />
                    <div className="create-recipe-btn-text">Save Changes</div>
                  </div>

                  <div className="cancel-recipe" onClick={() => navigate(-1)}>
                    <img className="cancel-recipe-x" src={cancel} />
                    <div className="create-recipe-btn-text">Cancel</div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {saveModalIsActive && (
        <ModalWithoutAck
          valid={true}
          title="Got it."
          message={getUpdateOrNewMessage()}
          delay={5000}
          onClose={() => {
            setSaveModalIsActive(false);
            navigate(Constants.APP_BROWSE);
          }}
        />
      )}

      {invalidInputModalIsActive && (
        <ModalWithAck
          valid={false}
          title="Invalid Recipe"
          message={whyRecipeIsInvalid}
          onClick={() => setInvalidInputModalIsActive(false)}
        />
      )}

      {imageSelectorModalIsActive && (
        <Modal isOpen={true} className="modal-dialog-centered">
          <ModalHeader>Select Replacement Image</ModalHeader>
          <ModalBody>
            {imageFileToUpload == null && (
              <input
                type="file"
                accept="image/*"
                onChange={handleImageFileChange}
              />
            )}
            <br />
            <div className="centered">
              {imageFileToUploadAsB64 && (
                <img src={imageFileToUploadAsB64} alt="New image" />
              )}
            </div>
            <br />
          </ModalBody>
          <ModalFooter>
            <div className="rightjustify">
              {imageFileToUpload != null && (
                <>
                  <Button color="info" onClick={() => useSelectedImage()}>
                    Use This Image
                  </Button>{" "}
                  &nbsp;
                </>
              )}
              <Button color="secondary" onClick={() => cancelSelectedImage()}>
                Never mind
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
